import * as zipCode from '@/utils/zipCode';
import { Toast } from 'vant';
import i18n from '@/i18n';
/**
 * @param {string} val
 * @returns {Boolean}
 */
export function validUserName(val, validator, isTips = true) {
    const nameLanguage = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]|[\u4E00-\u9FA5]/g;
    const nameReg = /^[a-zA-Z\s.]+$/;
    const namePoint = /^[.-]*$/;
    const isRight = !nameLanguage.test(val) && nameReg.test(val) && !namePoint.test(val);
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.nameTest'));
    }
    return isRight;
}
/**
 * @param {string} val
 * @returns {Boolean}
 */
export function valididCard(val, validator, isTips = true) {
    const Reg = /^\d+$/;
    const isRight = Reg.test(val) && val.length === 16;
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.idCardTest'));
    }
    return isRight;
}
/**
 * @param {string} whatsapp
 * @returns {Boolean}
 */
export function validWhatsapp(whatsapp, validator, isTips = true) {
    const whatsappnum = /^9/;
    const whatsappnumone = /^09/;
    const whatsappnum1one = /^63/;
    let isRight = true;
    if (whatsappnumone.test(whatsapp) && whatsapp.length !== 11) {
        if (isTips) {
            Toast.fail(i18n.t('rules.phoneLengthError'));
        }
        isRight = false;
    }
    if (whatsappnum.test(whatsapp) && whatsapp.length !== 10) {
        if (isTips) {
            Toast.fail(i18n.t('rules.phoneLengthError'));
        }
        isRight = false;
    }
    if (whatsappnum1one.test(whatsapp) && whatsapp.length !== 12) {
        if (isTips) {
            Toast.fail(i18n.t('rules.phoneLengthError'));
        }
        isRight = false;
    }
    // 不是09 9 63 开头
    if (!whatsappnum.test(whatsapp) && !whatsappnumone.test(whatsapp) && !whatsappnum1one.test(whatsapp)) {
        if (isTips) {
            Toast.fail(i18n.t('rules.iphoneTest'));
        }
        isRight = false;
    }
    console.log(isRight, 'isRight');
    // if (!isRight && isTips) {
    //   Toast.fail(i18n.t('rules.iphoneTest'))
    // }
    return isRight;
}
export function validAge(age, validator, isTips = true) {
    let reg = /^(?:[1-9][0-9]?|1[01][0-9]|180)$/;
    const isRight = reg.test(age);
    if (!isRight && isTips) {
        Toast.fail(i18n.t('cupones.qingshuruzhengquedeAge'));
    }
    return isRight;
}
/**
 * @param {string} companyPhone
 * @returns {Boolean}
 */
export function validCompanyPhone(whatsapp, validator, isTips = true) {
    let isRight = true;
    // 目前没有任何校验、如有可在下方添加 
    // Toast.fail(i18n.t('rules.iphoneTestNullFirst'))
    return isRight;
}
/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email, validator, isTips = true) {
    const reg = /^[\w.]+@[\w.]+$/;
    const isRight = reg.test(email);
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.emailTest'));
    }
    return isRight;
}
/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validZipCode(codeString, validator, isTips = true) {
    const firstStr = (codeString + '').substring(0, 1);
    const useMould = zipCode[`postCode${firstStr}`];
    const isRight = useMould && useMould.indexOf(codeString) >= 0;
    if (!isRight && isTips) {
        Toast.fail(i18n.t('navbar.postCodeerr'));
    }
    return isRight;
}
/**
 * @param {string} relationTel
 * @returns {Boolean}
 */
export function validRelationTel(relationTel, validator, isTips = true) {
    console.log('校验联系人手机号格式---');
    const whatsappnum = /^9/;
    const whatsappnumone = /^09/;
    const whatsappnum1one = /^63/;
    let isRight = true;
    if (whatsappnumone.test(relationTel) && relationTel.length !== 11) {
        if (isTips) {
            setTimeout(() => {
                Toast.fail(i18n.t('rules.phoneLengthError'));
            }, 500);
        }
        isRight = false;
    }
    if (whatsappnum.test(relationTel) && relationTel.length !== 10) {
        if (isTips) {
            setTimeout(() => {
                Toast.fail(i18n.t('rules.phoneLengthError'));
            }, 500);
        }
        isRight = false;
    }
    if (whatsappnum1one.test(relationTel) && relationTel.length !== 12) {
        if (isTips) {
            setTimeout(() => {
                Toast.fail(i18n.t('rules.phoneLengthError'));
            }, 500);
        }
        isRight = false;
    }
    // 不是09 9 63开头
    if (!whatsappnum.test(relationTel) && !whatsappnumone.test(relationTel)) {
        if (isTips) {
            setTimeout(() => {
                Toast.fail(i18n.t('rules.iphoneTest'));
            }, 500);
        }
        isRight = false;
    }
    // if (!isRight && isTips) {
    //   Toast.fail(i18n.t('rules.iphoneTest'))
    // }
    return isRight;
}
/**
 * @param {string} relationTel
 * @returns {Boolean}
 */
export function RelationshipPhone(relationTel, validator, isTips = true) {
    const whatsappnum = /^9/;
    const whatsappnumone = /^09/;
    const whatsappnum1one = /^63/;
    let isRight = true;
    if (whatsappnumone.test(relationTel) && relationTel.length !== 11) {
        isRight = false;
    }
    if (whatsappnum.test(relationTel) && relationTel.length !== 10) {
        isRight = false;
    }
    if (whatsappnum1one.test(relationTel) && relationTel.length !== 12) {
        isRight = false;
    }
    // 不是09 9 63开头
    if (!whatsappnum.test(relationTel) && !whatsappnumone.test(relationTel)) {
        isRight = false;
    }
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.selectRight'));
    }
    return isRight;
}
/**
 * @param {string} val
 * @returns {Boolean}
 */
export function validRfc(val, validator, isTips = false) {
    const Reg = /^([A-Z]{4})([0-9]{6})([0-9A-Z]{3})$/;
    const isRight = Reg.test(val);
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.rfcTest'));
    }
    return isRight;
}
/**
 * @param {string} val
 * @returns {Boolean}
 */
export function validSpecialStr(val, validator, isTips = true) {
    const Reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/;
    const chinesereg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]|[\u4E00-\u9FA5]/g;
    const isRight = !Reg.test(val) && !chinesereg.test(val);
    if (!isRight && isTips) {
        Toast.fail(i18n.t('rules.rfcTest'));
    }
    return isRight;
}
